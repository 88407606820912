
import { Component, Vue } from 'vue-property-decorator';
import '@/assets/images/marketing/screenshots/Notifications_mobile.png';
import Action from '@/modules/marketing/MarketingAction.vue';
import { Slider, SliderItem } from 'vue-easy-slider';

@Component({
  components: {
    Action,
    Slider,
    SliderItem,
  },
  layout: 'default',
  metaInfo: {
    title: 'Compliance ',
  },
})
export default class Compliance extends Vue {}
